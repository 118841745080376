'use client'

import * as React from 'react'
import * as z from 'zod'

import { useRouter } from 'next/navigation'
import { authService } from '@/api/auth'
import { ApiError, handleApiError } from '@/api/utils'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAuthStore } from '@/lib/auth'
import Link from 'next/link'

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8, { message: 'Password must be at least 8 characters long' })
})

export function LoginForm() {
  const router = useRouter()
  const auth = useAuthStore()

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  React.useEffect(() => {
    if (auth.user) {
      router.replace('/messages')
    }
  }, [])

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await authService.login(values.email, values.password)
      router.replace('/dashboard')
    } catch (err) {
      if (err instanceof ApiError) {
        handleApiError(err)
      }
    }
  }

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
          <div className='space-y-3'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder='Email' type='email' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input placeholder='Password' type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button type='submit' className='flex w-full' loading={form.formState.isSubmitting}>
            Submit
          </Button>
        </form>
      </Form>
      <div className='mt-4 text-center'>
        <Button asChild variant='link' className='text-purple-600 shadow-none'>
          <Link href='/auth/forgot-password'>Forgot your password?</Link>
        </Button>
      </div>
    </>
  )
}
